import React, { useState } from "react";
import casestudy from "../../assets/FlipImages/1.png";
import it from "../../assets/FlipImages/1.png";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import blog13 from "../../assets/images/blogs/blog13.jfif";
import latestblog from "../../assets/images/blogs/latestblogimg1.jpeg";
import fbar from "../../assets/images/blogs/FBAR1.png";
import fbar1 from "../../assets/images/blogs/FBAR2.png";
import fbar3 from "../../assets/images/blogs/FBAR4.png";
import rule2025 from '../../assets/images/blogs/rule-2025.png'
import rule12025 from '../../assets/images/blogs/rule1-2025.png'
import financeplan2025 from '../../assets/images/blogs/financeplan-2025.png'

const NavigatingNewRegulations = () => {
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [comments, setComments] = useState([]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleSaveInfoChange = (event) => {
    setSaveInfo(event.target.checked);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() && name.trim() && email.trim()) {
      setComments([...comments, { comment, name, email, website }]);
      setComment("");
      setName("");
      setEmail("");
      setWebsite("");
      setSaveInfo(false);
    }
  };

  return (
    <>
      <style jsx>{`
        h4 {
          font-size: 18px;
        }
        h3 {
          font-size: 28px;
        }
        span {
          font-size: 16px;
        }
        p {
          font-size: 16px;
        }
        li {
          font-size: 16px;
          font-weight: 500;
        }
        .mainCard {
          height: 20rem;
        }
        .circle {
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          overflow: hidden;
        }
        i {
          color: #072f5f;
        }
        ul {
          padding-left: 17px;
        }
        @media (max-width: 574px) {
          .mTop {
            margin-top: 30px;
          }
          p {
            font-size: 18px;
          }
          .img-fluid {
            min-height: 100px;
          }
          .container-fluid {
            padding-left: 15px;
            padding-right: 15px;
          }
        }
        @media (min-width: 992px) {
          /* for banner image */
          .bmTop {
            margin-top: 130px;
          }
        }
        @media (max-width: 574px) {
          .banner-image {
            margin-top: 70px; /* Adjust this value for mobile view */
          }
        }
      `}</style>

      <section className="">
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${casestudy}) no-repeat center`,
            backgroundSize: "cover",
            height: "200px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase text-center"
              style={{ fontWeight: "bold", marginTop: "10px" }}
            >
              Navigating New Regulations: What Every Financial <br /> Advisor
              Needs to Know Now
            </h3>
          </div>
        </div>
        <div className="container-fluid">
          <div
            className="d-flex row justify-content-center align-items-start"
            style={{ gap: "2rem" }}
          >
            <div
              className="col-lg-8 mt-50 mb-50 border shadow"
              style={{ padding: "2rem" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Introduction
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      ></span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      The economic landscape continues to evolve, and new laws
                      are dictating how financial advisors operate. From tax
                      legislation changes to compliance regulations, staying
                      ahead is crucial for Financial Advisory Services
                      practitioners. Whether offering CPA Services in Boston,
                      Business Financial Consultation, or Corporate Tax
                      Services, staying informed of regulatory updates is
                      necessary to remain compliant and optimize financial
                      planning for clients.
                    </p>
                  </div>

                  <div className="mt-15">
                    <span
                      className="text-capitalize"
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "700",
                        color: "#002249",
                      }}
                    >
                      Key Regulatory Changes Impacting Financial Advisory
                      Services:
                    </span>
                  </div>
                  <ul
                    className="text-dark servicep"
                    style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                    <li>
                      Financial planners face a complex regulatory landscape
                      that affects business consulting, accounting, and tax
                      planning. Below are some of the most significant
                      developments in 2025 and their consequences for financial
                      professionals:
                    </li>
                  </ul>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        1. Tax Planning and Consultation under New Tax
                        Legislation:
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        Tax laws worldwide are under increased government
                        scrutiny, further complicating tax planning and
                        consulting. In the United States, recently enacted
                        corporate tax rates, allowances, and global tax
                        compliance regulations impact individuals and
                        corporations alike. Boston CPA Services must keep pace
                        with these legislations to help clients minimize their
                        tax liabilities while ensuring full compliance.
                      </li>
                    </ul>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        2. Business Financial Consulting Amidst Economic
                        Changes:
                      </span>
                    </div>
                  </div>
                  <ul
                    className="text-dark servicep"
                    style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                    <li>
                      With ongoing economic instability, businesses seek expert
                      financial counsel to remain stable. Business financial
                      consulting increasingly requires deeper insight into
                      regulatory shifts, risk assessment, and financial
                      projections. AI-driven analytics and data modeling are
                      becoming essential tools in a financial advisor's toolkit
                      within this evolving landscape.
                    </li>
                  </ul>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                          textAlign: "justify",
                        }}
                      >
                        3. Dubai Accounting Services: Compliance with Global
                        Financial Regulations:
                      </span>
                    </div>
                  </div>
                  <ul
                    className="text-dark servicep"
                    style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                    <li>
                      Dubai continues to refine its financial regulatory
                      environment, impacting businesses operating there.
                      Accounting services in Dubai must comply with new
                      reporting requirements, VAT rules, and cross-border
                      taxation agreements. Companies expanding into
                      international markets rely on these services to navigate
                      regulatory challenges effectively.
                    </li>
                  </ul>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                          textAlign: "justify",
                        }}
                      >
                        4. Corporate Tax Services & Compliance Requirements:
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        Corporate tax legislation is evolving to accommodate
                        global economic changes and counter tax evasion.
                        Businesses require specialized advice in corporate tax
                        services to keep up and refine their tax strategies.
                        Financial planners must be well-versed in transfer
                        pricing, cross-border taxation, and new government
                        reporting guidelines.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        5. Outsourcing Financial Accounting for Effective
                        Compliance:
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        With stricter financial transparency requirements,
                        companies are increasingly relying on outsourced
                        financial accounting to ensure accuracy and compliance.
                        Outsourcing offers the benefit of leveraging expertise
                        while minimizing operational costs. AI-driven automation
                        also enhances efficiency, reducing the risk of
                        regulatory fines.
                      </li>
                    </ul>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        6. M&A Advisory Services & Due Diligence Under New
                        Regulations:
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        Stricter due diligence standards are impacting mergers
                        and acquisitions. M&A advisory services must now include
                        detailed regulatory assessments, financial risk
                        evaluations, and compliance verification. AI tools
                        facilitate market analysis and risk identification
                        before deals are finalized.
                      </li>
                    </ul>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        7. Global Financial Solutions & International Business
                        Consulting:
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        Cross-border business is under heightened scrutiny,
                        making international business consulting more critical
                        than ever. Global financial solutions providers must
                        navigate evolving trade agreements, tax treaties, and
                        compliance regulations to support international business
                        expansion. Firms require professional guidance to ensure
                        compliance with global financial standards while
                        optimizing tax structures.{" "}
                      </li>
                    </ul>

                    <li>Actionable Plans for Financial Professionals</li>
                    <ul>
                      Navigating new regulations requires proactive strategies.
                      Here are key steps financial advisors should take:
                    </ul>

                    <li>
                      Stay Current with Regulatory Changes: Financial advisors
                      must engage in continuous education and compliance
                      training.
                    </li>
                    <li>
                      Leverage AI and Automation: Implement AI-based financial
                      software to enhance compliance, reporting accuracy, and
                      data analysis.
                    </li>
                    <li>
                      Improve Risk Management Plans: Greater regulatory
                      oversight demands efficient risk assessment systems to
                      mitigate penalties.
                    </li>
                    <li>
                      Provide Tailored Financial Advisory Services: Customizing
                      services to address specific regulatory concerns adds
                      significant value for clients.
                    </li>
                    <li>
                      Collaborate with Global Financial Professionals:
                      Partnering with global financial professionals ensures
                      businesses receive comprehensive tax planning and
                      compliance support.
                    </li>
                  </div>

                  <img
                    src={rule12025}
                    style={{
                      display: "block",
                      margin: "1rem auto",
                      width: "350px",
                      height: "270px",
                    }}
                  />
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Conclusion:
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        Regulatory changes are reshaping the financial advisory
                        landscape. Whether providing CPA Services in Boston,
                        Corporate Tax Services, or Accounting Services in Dubai,
                        financial advisors must stay informed, embrace new
                        technology, and refine consulting strategies. Firms that
                        adapt early will gain a competitive edge, ensuring
                        efficient tax planning, compliance, and long-term
                        financial success.
                      </li>
                    </ul>
                  </div>

                  <Box
                    mt="2rem"
                    p="2rem"
                    border="1px solid #ddd"
                    borderRadius="8px"
                  >
                    <Typography variant="h5" gutterBottom>
                      Leave a Comment
                    </Typography>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={comment}
                      onChange={handleCommentChange}
                      margin="normal"
                    />
                    <Box display="flex" gap="1rem">
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        margin="normal"
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        margin="normal"
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveInfo}
                          onChange={handleSaveInfoChange}
                        />
                      }
                      label="Save my name, email, and website in this browser for the next time I comment."
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCommentSubmit}
                      style={{ backgroundColor: "rgb(0, 34, 73)" }}
                    >
                      Send Comment
                    </Button>
                  </Box>
                  <Box mt="2rem">
                    {comments.map((cmt, index) => (
                      <Box
                        key={index}
                        mt="1rem"
                        p="1rem"
                        border="1px solid #ddd"
                        borderRadius="8px"
                      >
                        <Typography variant="body1">{cmt.comment}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {cmt.name} - {cmt.email}
                          {cmt.website && ` - ${cmt.website}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NavigatingNewRegulations;
