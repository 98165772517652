import React, { useState } from "react";
import casestudy from "../../assets/FlipImages/1.png";
import it from "../../assets/FlipImages/1.png";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import blog13 from "../../assets/images/blogs/blog13.jfif";
import latestblog from "../../assets/images/blogs/latestblogimg1.jpeg";
import fbar from "../../assets/images/blogs/FBAR1.png";
import fbar1 from "../../assets/images/blogs/FBAR2.png";
import fbar3 from "../../assets/images/blogs/FBAR4.png";
import finance2025 from '../../assets/images/blogs/finance-2025.png'

const FinancialBlunders = () => {
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [comments, setComments] = useState([]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleSaveInfoChange = (event) => {
    setSaveInfo(event.target.checked);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() && name.trim() && email.trim()) {
      setComments([...comments, { comment, name, email, website }]);
      setComment("");
      setName("");
      setEmail("");
      setWebsite("");
      setSaveInfo(false);
    }
  };

  return (
    <>
      <style jsx>{`
        h4 {
          font-size: 18px;
        }
        h3 {
          font-size: 28px;
        }
        span {
          font-size: 16px;
        }
        p {
          font-size: 16px;
        }
        li {
          font-size: 16px;
          font-weight: 500;
        }
        .mainCard {
          height: 20rem;
        }
        .circle {
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          overflow: hidden;
        }
        i {
          color: #072f5f;
        }
        ul {
          padding-left: 17px;
        }
        @media (max-width: 574px) {
          .mTop {
            margin-top: 30px;
          }
          p {
            font-size: 18px;
          }
          .img-fluid {
            min-height: 100px;
          }
          .container-fluid {
            padding-left: 15px;
            padding-right: 15px;
          }
        }
        @media (min-width: 992px) {
          /* for banner image */
          .bmTop {
            margin-top: 130px;
          }
        }
        @media (max-width: 574px) {
          .banner-image {
            margin-top: 70px; /* Adjust this value for mobile view */
          }
        }
      `}</style>

      <section className="">
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${casestudy}) no-repeat center`,
            backgroundSize: "cover",
            height: "200px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase text-center"
              style={{ fontWeight: "bold", marginTop: "10px" }}
            >
              Are You Making These 3 Expensive Financial <br /> Blunders in Your
              Business?
            </h3>
          </div>
        </div>
        <div className="container-fluid">
          <div
            className="d-flex row justify-content-center align-items-start"
            style={{ gap: "2rem" }}
          >
            <div
              className="col-lg-8 mt-50 mb-50 border shadow"
              style={{ padding: "2rem" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Introduction
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      ></span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      Business finances are complex, and one small error can
                      result in significant financial loss. Whether you are a
                      startup or an established business, compliance and
                      maximizing your business finance strategy are key to
                      long-term success. Most businesses make expensive errors
                      without even realizing it, which impacts profitability,
                      tax efficiency, and regulatory compliance. In this blog,
                      we will highlight three significant financial blunders
                      businesses often commit and how Financial Advisory
                      Services can reduce risks.
                    </p>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        1. Ignoring Strategic Tax Planning and Compliance
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        One of the most prevalent and expensive errors that
                        companies make is failing to prioritize Tax Planning and
                        Consulting. Tax regulations are constantly evolving, and
                        failing to stay compliant can result in hefty penalties
                        and lost savings. U.S. companies, as well as those
                        operating internationally, need to keep pace with tax
                        law, particularly corporate tax policies and
                        cross-border taxation.
                      </li>
                    </ul>

                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      How to Avoid This Mistake:
                    </ul>

                    <li>
                      Partner with trusted CPA Services in Boston for tax
                      compliance and to maximize deductions.
                    </li>
                    <li>
                      Take advantage of expert International Business Consulting
                      to effectively manage global tax liabilities.
                    </li>
                    <li>
                      Apply AI-based accounting software to automate tax
                      calculations and reporting.{" "}
                    </li>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        2. Overlooking Financial Advisory and Consulting
                        Services
                      </span>
                    </div>
                
                  <ul
                    className="text-dark servicep"
                    style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                     <li>
                     Most business owners are preoccupied with daily routines and overlook long-term financial planning. Without Business Financial Consulting, businesses make poor investment choices, waste resources, and allocate cash flow ineffectively.
                      </li>
                    </ul>

                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      How to Prevent This Error:
                    </ul>

                    <li>
                    Hire Outsourced Financial Accounting to provide bookkeeping and financial analysis.
                    </li>
                    <li>
                    Make use of M&A Advisory Services in acquiring or merging to maximize value for the deal and minimize financial risks.
                    </li>
                    <li>
                    Use Dubai Accounting Services or other foreign financial experts when conducting business abroad.
                    </li>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                          textAlign: "justify",
                        }}
                      >
                        3. Failing to Utilize Outsourced Financial Services
                      </span>
                    </div>
                  
                  <ul
                    className="text-dark servicep"
                    style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                    <li>
                    Many corporations attempt to handle finances internally without adequate expertise, leading to inefficiencies and errors. Outsourcing financial functions can be time-effective, cost-efficient, and ensure regulatory compliance.
                    </li>
                  </ul>

                  <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      How to Avoid This Error:
                    </ul>

                    <li>
                   Partner with Global Financial Solutions providers for expert financial management.                    </li>
                    <li>
                   Take advantage of Corporate Tax Services to stay compliant with domestic and international tax laws.                    </li>
                    <li>
                  Utilize cloud-based financial management tools for real-time reporting and analysis.                    </li>
                  </div>

                  <img
                    src={finance2025}
                    style={{
                      display: "block",
                      margin: "1rem auto",
                      width: "350px",
                      height: "270px",
                    }}
                  />
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                       Conclusion
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                      Avoiding these three common financial blunders can significantly improve your business’s financial well-being and long-term success. Whether you need CPA Services in Boston, Business Financial Advisory, or Accounting Services in Dubai, collaborating with seasoned financial strategists will help you navigate regulatory environments and maximize financial planning.
                      </li>
                    </ul>
                  </div>

                  <Box
                    mt="2rem"
                    p="2rem"
                    border="1px solid #ddd"
                    borderRadius="8px"
                  >
                    <Typography variant="h5" gutterBottom>
                      Leave a Comment
                    </Typography>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={comment}
                      onChange={handleCommentChange}
                      margin="normal"
                    />
                    <Box display="flex" gap="1rem">
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        margin="normal"
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        margin="normal"
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveInfo}
                          onChange={handleSaveInfoChange}
                        />
                      }
                      label="Save my name, email, and website in this browser for the next time I comment."
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCommentSubmit}
                      style={{ backgroundColor: "rgb(0, 34, 73)" }}
                    >
                      Send Comment
                    </Button>
                  </Box>
                  <Box mt="2rem">
                    {comments.map((cmt, index) => (
                      <Box
                        key={index}
                        mt="1rem"
                        p="1rem"
                        border="1px solid #ddd"
                        borderRadius="8px"
                      >
                        <Typography variant="body1">{cmt.comment}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {cmt.name} - {cmt.email}
                          {cmt.website && ` - ${cmt.website}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FinancialBlunders;
