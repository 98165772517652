import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

// Import all images
import internaltax from "../assets/images/blogs/InternationalTaxEra1.png";
import fbar2 from "../assets/images/blogs/FBAR2.png";
import internaltax1 from "../assets/images/blogs/InternationalTaxEra2.png";
import taxing1 from "../assets/images/blogs/taxing1.png";
import sustain3 from "../assets/images/blogs/Sustainability3.png";
import envole2 from "../assets/images/blogs/bostonblog.png";
import newblogimg from "../assets/images/blogs/newblogimg2.png";
import virtual1 from "../assets/images/blogs/virtual1.png";
import taxtime from '../assets/images/blogs/taxtime1.png';
import taxtime1 from '../assets/images/blogs/taxtime2.png';
import taxtime2 from '../assets/images/blogs/taxtime3.png';
import fbar from '../assets/images/blogs/FBAR1.png';
import fbar1 from '../assets/images/blogs/FBAR2.png';
import fbar3 from '../assets/images/blogs/FBAR4.png';
import tax2025 from '../assets/images/blogs/tax-2025.png'
import tax12025 from '../assets/images/blogs/tax1-2025.png'
import finance2025 from '../assets/images/blogs/finance-2025.png'
import finance12025 from '../assets/images/blogs/finance1-2025.png'
import rule2025 from '../assets/images/blogs/rule-2025.png'
import rule12025 from '../assets/images/blogs/rule1-2025.png'
import financeplan2025 from '../assets/images/blogs/financeplan-2025.png'
import financeplan12025 from '../assets/images/blogs/financeplan1-2025.png'
import financeplan22025 from '../assets/images/blogs/financeplane-2-2025.png'

const BlogMobile = () => {
  const [showMore, setShowMore] = useState(false);

  // Mobile Blog Card Component
  const MobileBlogCard = ({ to, image, date, title }) => {
    return (
      <Link 
        to={to} 
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          borderRadius: "0.5rem",
          overflow: "hidden",
          marginBottom: "1rem",
          textDecoration: "none",
          color: "inherit"
        }}
      >
        <div 
          style={{ 
            height: "12rem",
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}
        />
        <div style={{ padding: "1rem" }}>
          <div style={{ 
            fontSize: "0.875rem", 
            color: "#6b7280", 
            marginBottom: "0.5rem" 
          }}>
            {date}
          </div>
          <h3 style={{ 
            fontSize: "1.125rem", 
            fontWeight: "600", 
            color: "#1f2937",
            margin: "0"
          }}>
            {title}
          </h3>
        </div>
      </Link>
    );
  };

  // Blog posts data
  const mobileBlogPosts = [
    {
      to: "/navigating-new-regulations",
      image: financeplan12025,
      date: "Apr 10, 2025",
      title: " Navigating New Regulations: What Every Financial Advisor Needs to Know Now"
    },
    {
      to: "/financial-blunders",
      image: financeplan22025,
      date: "Apr 5, 2025",
      title: "Are You Making These 3 Expensive Financial Blunders in Your Business?"
    },
    {
      to: "/tax-changes-to-prepare-for-this-year",
      image: tax2025,
      date: "Mar 28, 2025",
      title: "Are You Ready? The Top 7 Tax Changes to Prepare for This Year!"
    },
    {
      to: "/surprising-impact-of-ai",
      image: finance12025,
      date: "Mar 20, 2025",
      title: "The Surprising Impact of AI: Transforming Financial Advisory Services in 2025"
    },
    {
      to: "/international-tax-era",
      image: internaltax,
      date: "Mar 15, 2025",
      title: "Managing the New Global Minimum International Tax Era: Essential Facts for Multinationals"
    },
    {
      to: "/fbar-penalties-revealed",
      image: fbar2,
      date: "Mar 10, 2025",
      title: "FBAR Penalties Revealed: What Every Foreign Investor Needs to Know"
    },
    {
      to: "/cryptocurrency-your-taxes",
      image: internaltax1,
      date: "Mar 5, 2025",
      title: "Cryptocurrency and Your Taxes: A Guide for the Digital Age Investor"
    },
    {
      to: "/Navigating-us-india-cross-border",
      image: taxing1,
      date: "Feb 28, 2025",
      title: "Navigating US-India Cross-Border Taxation: Key Updates for 2025"
    },
    {
      to: "/sustainability-reporting",
      image: sustain3,
      date: "Feb 26, 2025",
      title: "Sustainability Reporting: The Growing Importance for CPAs, CAs, and CSs"
    },
    {
      to: "/evolving-role-accounting-professional",
      image: envole2,
      date: "Feb 25, 2025",
      title: "The Evolving Role of Accounting Professionals in ESG Practices: A 2025 Perspective"
    },
    {
      to: "/decoding-india-consumption-boom",
      image: newblogimg,
      date: "Feb 21, 2025",
      title: "Decoding India's Consumption Boom: BFAG's Investment Approaches to the World's Fastest-Growing Major Economy"
    },
    {
      to: "/virtual-cpa-services",
      image: virtual1,
      date: "Jan 14, 2025",
      title: "Virtual CPA Services: How to Maximize Your Business's Financial Health Remotely"
    },
    {
      to: "/cryptocurreny-taxes-explained",
      image: taxtime,
      date: "Jan 5, 2025",
      title: "Cryptocurrency Taxes Explained: A CPA's Guide to Navigating the Digital Currency Landscape"
    },
    {
      to: "/from-seed-to-scale",
      image: taxtime1,
      date: "Jan 3, 2024",
      title: "From Seed to Scale: How Pune's CAs are Fuelling the City's Startup Ecosystem"
    },
    {
      to: "/annual-inflation",
      image: taxtime2,
      date: "Dec 20, 2024",
      title: "Annual Inflation Adjustments for Tax Year 2025: What Boston Taxpayers Should Know"
    },
    {
      to: "/audited-financial-report",
      image: fbar,
      date: "Dec 15, 2024",
      title: "Meaning of Audited Financial Reports to Businesses"
    },
    {
      to: "/increasing-incidence-of-financial",
      image: fbar1,
      date: "Dec 11, 2024",
      title: "The Increasing Incidence of Financial Statement Retractions: Implications and Solutions for United States Companies"
    },
    {
      to: "/Supreme-court-defers-fundamental",
      image: fbar3,
      date: "Dec 7, 2024",
      title: "Supreme Court Defers Fundamental Constitutional Question in Upholding Tax on Foreign Business Income"
    },
    {
      to: "/IRS-again-delays-effective-date",
      image: internaltax1,
      date: "Dec 4, 2024",
      title: "IRS again delays effective date of $600 threshold for Form 1099-K reporting"
    },
    {
      to: "/navigating-crypto",
      image: taxtime,
      date: "August 11, 2024",
      title: "Navigating the Crypto Landscape: What Investors Should Know in 2024"
    },
    {
      to: "/financial-services",
      image: taxtime1,
      date: "August 7, 2024",
      title: "The Rise of AI in Financial Services: What Investors Need to Know"
    }
  ];

  // Additional blog posts for "Show More" functionality
  const additionalMobileBlogPosts = [
    // Add more blog posts here if needed
  ];

  return (
    <div style={{ 
      display: "block", 
      padding: "1rem"
    }}>
      <div style={{ 
        paddingBottom: "1rem", 
        marginBottom: "1rem", 
        textAlign: "center" 
      }}>
        <h3 style={{ 
          fontSize: "1.5rem", 
          fontWeight: "700", 
          color: "#072f5f", 
          marginTop: "90px" 
        }}>
          Insights from Boston Financial
        </h3>
      </div>

      <div style={{ marginBottom: "1rem" }}>
        {mobileBlogPosts.map((post, index) => (
          <MobileBlogCard 
            key={index}
            to={post.to}
            image={post.image}
            date={post.date}
            title={post.title}
          />
        ))}

        {showMore && additionalMobileBlogPosts.map((post, index) => (
          <MobileBlogCard 
            key={index}
            to={post.to}
            image={post.image}
            date={post.date}
            title={post.title}
          />
        ))}
      </div>

      <div style={{ 
        textAlign: "center", 
        marginTop: "1rem" 
      }}>
        <Button
          variant="contained"
          color="primary"
          style={{ 
            backgroundColor: "#072f5f",
            textTransform: "none",
            padding: "0.5rem 1.5rem",
            borderRadius: "0.25rem",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
          }}
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? "Show Less" : "Read More"}
        </Button>
      </div>
    </div>
  );
};

export default BlogMobile;