import React, { useState } from "react";
import casestudy from "../../assets/FlipImages/1.png";
import it from "../../assets/FlipImages/1.png";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import blog13 from "../../assets/images/blogs/blog13.jfif";
import latestblog from "../../assets/images/blogs/latestblogimg1.jpeg";
import fbar from "../../assets/images/blogs/FBAR1.png";
import fbar1 from "../../assets/images/blogs/FBAR2.png";
import fbar3 from "../../assets/images/blogs/FBAR4.png";
import tax12025 from '../../assets/images/blogs/tax1-2025.png'

const TaxChangestoPrepareforthisYear = () => {
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [comments, setComments] = useState([]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleSaveInfoChange = (event) => {
    setSaveInfo(event.target.checked);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() && name.trim() && email.trim()) {
      setComments([...comments, { comment, name, email, website }]);
      setComment("");
      setName("");
      setEmail("");
      setWebsite("");
      setSaveInfo(false);
    }
  };

  return (
    <>
      <style jsx>{`
        h4 {
          font-size: 18px;
        }
        h3 {
          font-size: 28px;
        }
        span {
          font-size: 16px;
        }
        p {
          font-size: 16px;
        }
        li {
          font-size: 16px;
          font-weight: 500;
        }
        .mainCard {
          height: 20rem;
        }
        .circle {
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          overflow: hidden;
        }
        i {
          color: #072f5f;
        }
        ul {
          padding-left: 17px;
        }
        @media (max-width: 574px) {
          .mTop {
            margin-top: 30px;
          }
          p {
            font-size: 18px;
          }
          .img-fluid {
            min-height: 100px;
          }
          .container-fluid {
            padding-left: 15px;
            padding-right: 15px;
          }
        }
        @media (min-width: 992px) {
          /* for banner image */
          .bmTop {
            margin-top: 130px;
          }
        }
        @media (max-width: 574px) {
          .banner-image {
            margin-top: 70px; /* Adjust this value for mobile view */
          }
        }
      `}</style>

      <section className="">
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${casestudy}) no-repeat center`,
            backgroundSize: "cover",
            height: "200px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase text-center"
              style={{ fontWeight: "bold", marginTop: "10px" }}
            >
              Are You Ready? The Top 7 Tax Changes to <br /> Prepare for This
              Year!
            </h3>
          </div>
        </div>
        <div className="container-fluid">
          <div
            className="d-flex row justify-content-center align-items-start"
            style={{ gap: "2rem" }}
          >
            <div
              className="col-lg-8 mt-50 mb-50 border shadow"
              style={{ padding: "2rem" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Introduction
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      ></span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      As we start the new tax year, it's important for
                      individuals and businesses to stay informed about the
                      latest tax updates. At Boston FA Group, we aim to offer
                      comprehensive Financial Advisory Services and CPA Services
                      in Boston to help you maximize the benefits of these
                      changes. Let's discuss the top seven tax updates you
                      should prepare for this year.
                    </p>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        1. Changes in Income Tax Brackets and Rates
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        The IRS has issued inflation adjustments for the 2025
                        tax year, affecting tax brackets and rates for
                        individuals and married couples filing jointly:
                      </li>
                    </ul>
                    <li>
                      The joint filing standard deduction for couples has
                      increased to $30,000, reflecting an $800 rise from tax
                      year 2024.{" "}
                    </li>
                    <li>
                      For unmarried individuals and heads of households, the
                      standard deduction has increased to $15,000, a $400 rise
                      from 2024.
                    </li>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      Our Tax Planning and Consulting professionals can help you
                      assess how these changes impact your financial situation
                      and adjust your tax strategy accordingly.
                    </ul>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        2. Revisions in Corporate Tax Rates
                      </span>
                    </div>
                  </div>
                  <ul
                    className="text-dark servicep"
                    style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                    <li>
                      Though federal corporate tax rates are set, states are
                      modifying their corporate tax regimes. For example:
                    </li>
                  </ul>
                  <li>
                    Three states are lowering business income taxes in 2025.
                  </li>
                  <ul>
                    As one of our Business Financial Consulting offerings, we
                    can help you navigate these updates and optimize your
                    business strategy.
                  </ul>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                          textAlign: "justify",
                        }}
                      >
                        3. New First-Year Expensing Provisions
                      </span>
                    </div>
                  </div>
                  <ul
                    className="text-dark servicep"
                    style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                    <li>
                      Two states are implementing new first-year expensing
                      provisions in 2025. This can significantly impact
                      businesses' ability to expense certain assets upon service
                      activation. Our Corporate Tax Services team can guide you
                      in leveraging these provisions to maximize savings.
                    </li>
                  </ul>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                          textAlign: "justify",
                        }}
                      >
                        4. Alternative Minimum Tax (AMT) Exemption Changes
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        For tax year 2025, the AMT exemption threshold has
                        increased:
                      </li>
                    </ul>
                    <li>
                      Single filers: $88,100, with a phase-out beginning at
                      $626,350.
                    </li>
                    <li>
                      Joint filers: $137,000, with a phase-out starting at
                      $1,252,700.
                    </li>
                    <ul>
                      Our Global Financial Solutions approach ensures a holistic
                      tax strategy, factoring in all relevant tax changes.
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        5. Changes to Retirement Account Contributions
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        The IRS has introduced several retirement-related
                        updates for 2025:
                      </li>
                    </ul>
                    <li>
                      The 401(k) contribution limit will rise to $23,500, up
                      from $23,000 in 2024.
                    </li>
                    <li>
                      Investors aged 60 to 63 can now contribute a higher
                      catch-up limit of $11,250.
                    </li>
                    <ul>
                      Our Financial Accounting Outsourcing services can help
                      businesses remain compliant while maximizing employees'
                      retirement benefits.
                    </ul>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        6. State-Specific Tax Changes
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        Several states are implementing notable tax changes in
                        2025:
                      </li>
                    </ul>
                    <li>
                      Nine states are lowering individual income tax rates.{" "}
                    </li>
                    <li>
                      Iowa and Louisiana are shifting to single-rate income
                      taxation.{" "}
                    </li>
                    <li>
                      New Hampshire has eliminated its tax on interest and
                      dividend income.
                    </li>
                    <ul>
                      As International Business Consultants, we can guide you
                      through these jurisdictional tax changes, especially if
                      you operate in multiple states.
                    </ul>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        7. Gift Tax Exclusion Changes
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>In 2025, the annual gift tax exclusion increases:</li>
                    </ul>
                    <li>
                      The individual exemption rises to $19,000, up from $18,000
                      in 2024.
                    </li>
                    <li>
                      The exclusion for non-U.S. citizen spouses is now
                      $190,000.
                    </li>
                    <ul>
                      Our M&A Advisory Services team can assist you in
                      structuring your wealth transfer strategy to take
                      advantage of these adjustments.
                    </ul>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Preparation for These Changes
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        To effectively adapt to these tax updates, consider the
                        following steps:
                      </li>
                    </ul>
                    <li>
                      Evaluate your current tax status and how these changes
                      impact your personal or business finances.
                    </li>
                    <li>
                      Consult a tax professional to create a tailored tax
                      strategy.
                    </li>
                    <li>
                      Adjust your withholdings or estimated tax payments if
                      necessary.
                    </li>
                    <li>
                      Explore tax-advantaged investment opportunities or
                      deductions.
                    </li>
                    <li>
                      Stay informed on evolving tax laws to ensure compliance.
                    </li>
                  </div>

                  <ul>
                    <li>
                      At Boston FA Group, our team of experts in Financial
                      Advisory Services, Boston CPA Services, and Dubai
                      Accounting Services is here to help you navigate these
                      intricate tax updates and optimize your financial
                      strategy.
                    </li>
                  </ul>

                  <img
                    src={tax12025}
                    style={{
                      display: "block",
                      margin: "1rem auto",
                      width: "350px",
                      height: "270px",
                    }}
                  />
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Final Thoughts
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        Don't let these tax changes catch you off guard. Contact
                        Boston FA Group today to schedule a consultation and
                        ensure you're fully prepared for the new tax year. Our
                        Global Financial Solutions strategy provides you with
                        the expertise and confidence to achieve your financial
                        goals while staying tax-compliant.{" "}
                      </li>
                    </ul>
                  </div>

                  <Box
                    mt="2rem"
                    p="2rem"
                    border="1px solid #ddd"
                    borderRadius="8px"
                  >
                    <Typography variant="h5" gutterBottom>
                      Leave a Comment
                    </Typography>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={comment}
                      onChange={handleCommentChange}
                      margin="normal"
                    />
                    <Box display="flex" gap="1rem">
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        margin="normal"
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        margin="normal"
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveInfo}
                          onChange={handleSaveInfoChange}
                        />
                      }
                      label="Save my name, email, and website in this browser for the next time I comment."
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCommentSubmit}
                      style={{ backgroundColor: "rgb(0, 34, 73)" }}
                    >
                      Send Comment
                    </Button>
                  </Box>
                  <Box mt="2rem">
                    {comments.map((cmt, index) => (
                      <Box
                        key={index}
                        mt="1rem"
                        p="1rem"
                        border="1px solid #ddd"
                        borderRadius="8px"
                      >
                        <Typography variant="body1">{cmt.comment}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {cmt.name} - {cmt.email}
                          {cmt.website && ` - ${cmt.website}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TaxChangestoPrepareforthisYear;
