import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import blog1 from "../assets/images/blogs/blog1.jfif";
import blog2 from "../assets/images/blogs/blog2.jfif";
import blog3 from "../assets/images/blogs/blog15.png";
import blog4 from "../assets/images/blogs/blog5.jfif";
import flip6 from '../assets/FlipImages/flip6.jfif';
import { Button } from "@mui/material";
import "./BlogOne.css";
import { Helmet } from "react-helmet";

const BlogOne = () => {
  const blogs = [
    {
      img: blog4,
      date: "Posted On 27 Apr 2022 | admin@bfag",
      title: "Boston Financial: Holding SMEs' F&A Strings Right Enough to Let Them Fly",
      link: "https://consultants.siliconindia.com/vendor/boston-financial-holding-smes--fa-strings-right-enough-to-let-them-fly-cid-256.html",
    },
    {
      img: blog3,
      date: "Posted On 12 June 2023 | admin@bfag",
      title: "Listed as top 25th accounting firm in Boston",
      link: "https://themanifest.com/accounting/financial/firms/boston",
    },
    {
      img: blog1,
      date: "Posted On 24 March 2020 | admin@bfag",
      title: "Tech-driven Accounting & Financial Consulting Services to Help Brands During Crisis",
      link: "https://www.prnewswire.com/in/news-releases/tech-driven-accounting-amp-financial-consulting-services-to-help-brands-during-crisis-832660697.html",
    },
    {
      img: flip6,
      date: "Posted On 24 March 2020 | admin@bfag",
      title: "Tech-driven Accounting & Financial Consulting Services to Help Brands During Crisis",
      link: "https://www.tmcnet.com/usubmit/2020/03/24/9119676.htm",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Our Publications - Boston Financial Advisory Group</title>
        <meta
          name="description"
          content="Explore BFAG's expert insights on finance, accounting, law & compliance. Access in-depth articles, case studies & updates to stay informed and make better business decisions."
        />
        <meta
          name="keywords"
          content="Financial advisory firm, Financial consulting firm, Tax preparation, Financial consulting company"
        />
      </Helmet>

      <section className="publications-section bg-light py-5">
        <div className="container">
          {/* Header Section */}
          <div className="row mb-5">
            <div className="col-12 text-center">
              <h2 className="text-uppercase fw-bold mb-4" style={{ color: "#000" }}>
                Our Publications
              </h2>
              <div className="header-underline mx-auto mb-4" style={{ width: "80px", height: "3px", backgroundColor: "#072f5f" }}></div>
            </div>
          </div>

          {/* Blog Cards Section */}
          <div className="row g-4">
            {blogs.map((blog, index) => (
              <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                <Link to={blog.link} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                  <div 
                    className="blog-card bg-white rounded overflow-hidden shadow h-100" 
                    style={{ animationDelay: `${index * 0.2}s` }}
                  >
                    <div className="blog-image-container position-relative" style={{ height: "220px", overflow: "hidden" }}>
                      <img
                        src={blog.img}
                        alt={`Blog Post ${index + 1}`}
                        className="w-100 h-100 object-fit-cover"
                      />
                    </div>
                    <div className="blog-content p-4 position-relative">
                      <span className="blog-date d-block mb-2 fs-6" style={{ color: "#072f5f" }}>
                        {blog.date}
                      </span>
                      <h3 className="blog-title fw-bold mb-0" style={{ fontSize: "16px", color: "#333" }}>
                        {blog.title}
                      </h3>
                      <div className="read-more mt-3">
                        <span className="text-primary small fw-bold">Read More</span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogOne;